import { useEffect, useState } from 'react';
import React from 'react';
import useStore from '../../lib/hooks/use-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import Card from '../../components/ui/Card';
import Content from '../../components/layout/Content';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/ui/Alert';
import { useUser } from '../../lib/hooks/use-user';
import { ConfirmTOS } from '../../components/forms/ConfirmTOS';
import { useSignOut } from '../../lib/auth/use-signout';

const Home = React.memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useStore({ shouldListen: false });
  const navigate = useNavigate();
  const signOut = useSignOut();
  const checkAccess = useCheckAccess();
  const { user, error, update } = useUser();
  const date = new Date();
  const hours = date.getHours();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const goToOrders = (status: string) => {
    if (!checkAccess(['tae'])) {
      dispatch('SET_STATUS', [status]);
      navigate(`/orders`);
    } else if (checkAccess(['tae'])) {
      dispatch('SET_STATUS_LEAD', [status]);
      navigate(`/leads`);
    }
  };

  const goToTargets = () => {
    navigate(`/targets`);
  };

  const optionsOrderVolume = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#fff',
        },
      },
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          const data = tooltipItem.chart.data;
          const datasetIndex = tooltipItem.datasetIndex;
          const index = tooltipItem.dataIndex;
          const datasetLabel = data.datasets[datasetIndex].label || '';
          // You can use two type values.
          // `data.originalData` is raw values,
          // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
          const originalValue = data.originalData[datasetIndex][index];
          const rateValue = data.calculatedData[datasetIndex][index];

          return `${datasetLabel}: ${rateValue}% (raw ${originalValue})`;
        },
      },
    },
    plugins: {
      stacked100: { enable: true, replaceTooltipLabel: false },
      title: {
        text: 'Chatbot adoption',
        display: true,
        color: '#fff',
      },
      legend: {
        display: false,
      },
    },
  };

  const dataOrderVolume = {
    labels: [''],
    datasets: [
      {
        label: 'Chatbot',
        data: [user ? user.chatbotFulfilled : 0],
        backgroundColor: ['#94c12e'],
        borderColor: ['#94c12e'],
        borderWidth: 1,
      },
      {
        label: 'FSA',
        data: [user ? user.appFulfilled : 0],
        backgroundColor: ['#04bbf1'],
        borderColor: ['#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsTargetsCement = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly cement targets (tons)',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataCementTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets ? user.targets.cementTarget : 0,
          user && user.targets
            ? (user.ordersVolumeAppFulfilled.classic +
                user.ordersVolumeAppFulfilled.supaset +
                user.ordersVolumeChatbotFulfilled.classic +
                user.ordersVolumeChatbotFulfilled.supaset +
                user.ordersVolumeAppFulfilled.watershield +
                user.ordersVolumeChatbotFulfilled.watershield) *
              //tempProduct1 user.ordersVolumeAppFulfilled.tempProduct1 +
              //tempProduct1 user.ordersVolumeChatbotFulfilled.tempProduct1
              //tempProduct2 user.ordersVolumeAppFulfilled.tempProduct2 +
              //tempProduct2 user.ordersVolumeChatbotFulfilled.tempProduct2
              //tempProduct3 user.ordersVolumeAppFulfilled.tempProduct3 +
              //tempProduct3 user.ordersVolumeChatbotFulfilled.tempProduct3
              //tempProduct4 user.ordersVolumeAppFulfilled.tempProduct4 +
              //tempProduct4 user.ordersVolumeChatbotFulfilled.tempProduct4
              0.05
            : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsTargetsMortar = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly mortar targets (tons)',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataMortarTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets ? user.targets.mortarTarget : 0,
          user && user.targets
            ? (user.ordersVolumeAppFulfilled.supafixc0 +
                user.ordersVolumeAppFulfilled.supafixc1 +
                user.ordersVolumeChatbotFulfilled.supafixc0 +
                user.ordersVolumeChatbotFulfilled.supafixc1) *
              0.02
            : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsTargetsVisits = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly visits targets',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataVisitsTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets ? user.targets.visitTarget : 0,
          user && user.visitsAmount ? user.visitsAmount : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsSiteVisitsTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly site visits targets',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataSiteVisitsTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets ? user.targets.siteVisitTarget : 0,
          user && user.siteVisitsAmount ? user.siteVisitsAmount : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsLeadsTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly lead targets',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataLeadsTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets ? user.targets.leadAmountTarget : 0,
          user && user.leadsAmountFulfilled ? user.leadsAmountFulfilled : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  const optionsLeadsVolumeTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#fff',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly leads volume targets',
        display: true,
        color: '#fff',
      },
    },
  };

  const dataLeadsVolumeTargets = {
    labels: ['Target', 'Actual'],
    datasets: [
      {
        data: [
          user && user.targets
            ? user.targets.leadMortarTarget + user.targets.leadCementTarget
            : 0,
          user && user.leadsVolumeFulfilled
            ? (user.leadsVolumeFulfilled.classic +
                user.leadsVolumeFulfilled.supaset +
                user.leadsVolumeFulfilled.watershield) *
                //tempProduct1 user.leadsVolumeFulfilled.tempProduct1 +
                //tempProduct2 user.leadsVolumeFulfilled.tempProduct2 +
                //tempProduct3 user.leadsVolumeFulfilled.tempProduct3 +
                //tempProduct4 user.leadsVolumeFulfilled.tempProduct4
                0.05 +
              (user.leadsVolumeFulfilled.supafixc0 +
                user.leadsVolumeFulfilled.supafixc1) *
                0.02
            : 0,
        ],
        backgroundColor: ['#94c12e', '#04bbf1'],
        borderColor: ['#94c12e', '#04bbf1'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    if (user && !user.appTOSAccepted) {
      setShowConfirmation(true);
    }
  }, [user]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    update({ appTOSAccepted: true });
  };

  const handleOnCancel = async () => {
    setShowConfirmation(false);
    await signOut();
    navigate('/', { replace: true });
  };

  return (
    <Content>
      <ConfirmTOS
        title={
          'By using this application you consent to the collection and processing of your personal data in accordance with the Lafarge Africa privacy policy. '
        }
        link="https://www.lafarge.com.ng/privacy-policy"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
        confirmText="Accept"
        cancelText="Logout"
      />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Card>
        <div className="w-full grid grid-cols-12 gap-2 justify-center">
          <div
            className="grid grid-cols-12 col-span-12 h-12 text-md font-bold text-white text-center align-middle rounded-lg p-2 pt-3 bg-lh-dark-blue cursor-pointer"
            onClick={() => navigate('/profile')}
          >
            <h2 className="col-span-12 ">
              {user &&
                'Good ' +
                  (hours < 12 && hours > 3
                    ? 'morning'
                    : hours >= 12 && hours < 18
                    ? 'afternoon'
                    : 'evening') +
                  ', ' +
                  user.name}
            </h2>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToOrders('pending')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">Pending</p>
            <p className="text-center col-span-12 text-2xl">
              {user &&
              checkAccess(['fsa', 'distributor', 'sop', 'fsm']) &&
              user.ordersPending
                ? user.ordersPending
                : user && checkAccess(['tae']) && user.leadsAmountPending
                ? user.leadsAmountPending
                : 0}
            </p>
          </div>
          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-light-green"
              onClick={() => goToOrders('accepted')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Accepted
              </p>
              <p className="text-center col-span-12 text-2xl">
                {user && user.ordersAccepted}
              </p>
            </div>
          )}
          <div
            className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-light-blue cursor-pointer"
            onClick={() => goToOrders('fulfilled')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Fulfilled
            </p>
            <p className="text-center col-span-12 text-2xl">
              {user &&
              checkAccess(['fsa', 'distributor', 'sop', 'fsm']) &&
              user.ordersFulfilled
                ? user.ordersFulfilled
                : user && checkAccess(['tae']) && user.leadsAmountFulfilled
                ? user.leadsAmountFulfilled
                : 0}
            </p>
          </div>
          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-24 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-red"
              onClick={() => goToOrders('rejected')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Rejected
              </p>
              <p className="text-center col-span-12 text-2xl">
                {user && user.ordersRejected}
              </p>
            </div>
          )}
          {!checkAccess(['tae']) && (
            <div className="grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-1 bg-lh-dark-blue">
              <Bar
                options={optionsOrderVolume as any}
                plugins={[ChartjsPluginStacked100 as any]}
                data={dataOrderVolume}
              />
            </div>
          )}
          {checkAccess(['tae', 'fsm', 'sop']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-1 bg-lh-dark-blue ' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsLeadsVolumeTargets as any}
                data={dataLeadsVolumeTargets}
              />
            </div>
          )}
          {!checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue ' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsCement as any}
                data={dataCementTargets}
              />
            </div>
          )}
          {checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue ' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsLeadsTargets as any}
                data={dataLeadsTargets}
              />
            </div>
          )}
          {checkAccess(['sop', 'fsa', 'fsm']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
              onClick={() => navigate('/retailers/new')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Add new retailer
              </p>
              <p className="text-center col-span-12 text-2xl">
                <FontAwesomeIcon icon={faUserPlus} />
              </p>
            </div>
          )}
          {checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
              onClick={() => navigate('/sites/new')}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Add new site
              </p>
              <p className="text-center col-span-12 text-2xl">
                <FontAwesomeIcon icon={faUserPlus} />
              </p>
            </div>
          )}
          {!checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsMortar as any}
                data={dataMortarTargets}
                className="h-24"
              />
            </div>
          )}
          {checkAccess(['tae', 'sop', 'fsm']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsSiteVisitsTargets as any}
                data={dataSiteVisitsTargets}
                className="h-24"
              />
            </div>
          )}
          {checkAccess(['fsa', 'distributor', 'sop', 'fsm']) && (
            <div className="grid grid-cols-12 col-span-6 col h-36 text-lg font-medium text-white text-center align-middle rounded-lg p-2 pt-3 bg-lh-dark-blue">
              <p className="text-center col-span-12 text-md pt-10">
                Coming soon!
              </p>
            </div>
          )}
          {checkAccess(['fsa', 'sop', 'fsm']) && (
            <div
              className={
                'grid grid-cols-12 col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsVisits as any}
                data={dataVisitsTargets}
              />
            </div>
          )}

          {/*
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('numberWrong')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              No WhatsApp
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI
            </p>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('phoneChanged')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Phone changed
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI
            </p>
          </div>
          <div
            className="grid grid-cols-12 col-span-6 row-span-2 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-lh-dark-blue cursor-pointer"
            onClick={() => goToRetailers('all')}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Total retailers
            </p>
            <p className="text-center col-span-12 text-2xl">
              {/* TODO insert KPI 
            </p>
          </div>
        */}
        </div>
      </Card>
    </Content>
  );
});

export default Home;
