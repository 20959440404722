import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';
import Select from '../../../components/forms/Select';

const CEMENT_TYPES = [
  {
    key: 'comp2_42n',
    label: 'Comp 2 (42.5N)',
    value: 'comp2_42n',
  },
  {
    key: 'comp1_32r',
    label: 'Comp 1 (32.5R)',
    value: 'comp1_32r',
  },
  {
    key: 'comp1_42n',
    label: 'Comp 1 (42.5N)',
    value: 'comp1_42n',
  },
  {
    key: 'comp1_42r',
    label: 'Comp 1 (42.5R)',
    value: 'comp1_42r',
  },
  {
    key: 'comp3_32n',
    label: 'Comp 3 (32.5N)',
    value: 'comp3_32n',
  },
  {
    key: 'elephant_classic_32n',
    label: 'Elephant Classic (32.5N)',
    value: 'elephant_classic_32n',
  },
  {
    key: 'ecoplanet_32r',
    label: 'ECOPlanet UNICEM  (32.5R)',
    value: 'ecoplanet_32r',
  },
  {
    key: 'ashcem_32n',
    label: 'ASHCEM (32.5N)',
    value: 'ashcem_32n',
  },
  {
    key: 'supaset_42n',
    label: 'Supaset 42.5',
    value: 'supaset_42n',
  },
  {
    key: 'powermax_42n',
    label: 'Powermax 42.5N',
    value: 'powermax_42n',
  },
  {
    key: 'watershield_42n',
    label: 'Watershield 42.5N',
    value: 'watershield_42n',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
  {
    key: 'project_not_started',
    label: 'Project had not gotten to the stage',
    value: 'project_not_started',
  },
];

const REASON_FOR_NOT_USING = [
  {
    key: 'unavailability',
    label: 'Unavailability',
    value: 'unavailability',
  },
  {
    key: 'proximity_of_purchase',
    label: 'Proximity of purchase',
    value: 'proximity_of_purchase',
  },
  {
    key: 'price',
    label: 'Price',
    value: 'price',
  },
  {
    key: 'brand_name',
    label: 'Brand name',
    value: 'brand_name',
  },
  {
    key: 'credit_facility',
    label: 'Credit facility',
    value: 'credit_facility',
  },
  {
    key: 'colour',
    label: 'Colour',
    value: 'colour',
  },
  {
    key: 'others',
    label: 'Others (specify)',
    value: 'others',
  },
];

type Props = {
  editable: boolean;
  setProductTypes: any;
  setProductTypesIsDirty: any;
  setProductTypesIsValid: any;
  productTypes: {
    cementTypeSandcrete: string;
    cementTypeSandcreteOther: string;
    cementTypeConcrete: string;
    cementTypeConcreteOther: string;
    cementTypePlastering: string;
    cementTypePlasteringOther: string;
    reasonWhyNotUsed: string;
    reasonWhyNotUsedOther: string;
    tileAdhesiveIntent: string;
    nextVisitDate: string;
    feedback: string;
  };
};

const SiteVisitProductTypes = forwardRef((props: Props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: { ...props.productTypes },
    mode: 'all',
  });

  const cementTypeSandcrete = useWatch({
    control,
    name: 'cementTypeSandcrete',
  });
  const cementTypeSandcreteOther = useWatch({
    control,
    name: 'cementTypeSandcreteOther',
  });

  const cementTypeConcrete = useWatch({
    control,
    name: 'cementTypeConcrete',
  });
  const cementTypeConcreteOther = useWatch({
    control,
    name: 'cementTypeConcreteOther',
  });

  const cementTypePlastering = useWatch({
    control,
    name: 'cementTypePlastering',
  });
  const cementTypePlasteringOther = useWatch({
    control,
    name: 'cementTypePlasteringOther',
  });

  const reasonWhyNotUsed = useWatch({
    control,
    name: 'reasonWhyNotUsed',
  });
  const reasonWhyNotUsedOther = useWatch({
    control,
    name: 'reasonWhyNotUsedOther',
  });

  const tileAdhesiveIntent = useWatch({
    control,
    name: 'tileAdhesiveIntent',
  });

  const nextVisitDate = useWatch({
    control,
    name: 'nextVisitDate',
  });

  const feedback = useWatch({
    control,
    name: 'feedback',
  });

  const cementTypeSandcreteOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypeSandcreteOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const cementTypeConcreteOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypeConcreteOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const cementTypePlasteringOptions: RegisterOptions = {
    required: 'Please select a type!',
  };
  const cementTypePlasteringOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };  

  const reasonWhyNotUsedOptions: RegisterOptions = {
    required: 'Please select a reason!',
  };
  const reasonWhyNotUsedOtherOptions: RegisterOptions = {
    required: 'Please specify!',
  };

  const tileAdhesiveIntentOptions: RegisterOptions = {
    required: 'Please select an intent!',
  };

  const nextVisitDateOptions: RegisterOptions = {
    required: 'Please select a date!',
  };

  const feedbackOptions: RegisterOptions = {
    required: 'Please provide a feedback!',
  };

  useEffect(() => {
    props.setProductTypesIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
   // Set the product types is valid if all the required fields are filled and make sure if the other option is selected, the other field is filled
   if(
    ((cementTypeSandcrete && cementTypeSandcrete !== 'placeholder' && cementTypeSandcrete !== 'others') ||
    (cementTypeSandcrete === 'others' && cementTypeSandcreteOther) &&
    (cementTypeConcrete && cementTypeConcrete !== 'placeholder' && cementTypeConcrete !== 'others') ||
    (cementTypeConcrete === 'others' && cementTypeConcreteOther) &&
    (cementTypePlastering && cementTypePlastering !== 'placeholder' && cementTypePlastering !== 'others') ||
    (cementTypePlastering === 'others' && cementTypePlasteringOther)) && (
    reasonWhyNotUsed && reasonWhyNotUsed !== 'placeholder' && reasonWhyNotUsed !== 'others' ||
    (reasonWhyNotUsed === 'others' && reasonWhyNotUsedOther) &&
    tileAdhesiveIntent &&
    nextVisitDate &&
    feedback
    )
   ) {
    props.setProductTypesIsValid(true);
   } else {
    props.setProductTypesIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cementTypeSandcrete, cementTypeConcrete, cementTypePlastering]);

  useImperativeHandle(ref, () => ({
    saveProductTypes() {
      dosSaveProductTypes();
    },
  }));

  const dosSaveProductTypes = () => {
    handleSubmit(props.setProductTypes)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <label
            htmlFor="cementTypeSandcrete"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Sandcrete Block
          </label>
          <Select
            register={register}
            name="cementTypeSandcrete"
            items={CEMENT_TYPES}
            placeholder="Select cement type..."
            error={errors.cementTypeSandcrete}
            options={cementTypeSandcreteOptions}
            className="col-span-12"
          />
          {cementTypeSandcrete === 'others' && (
            <Input
              register={register}
              name="cementTypeSandcreteOther"
              error={errors.cementTypeSandcreteOther}
              options={cementTypeSandcreteOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="cementTypeConcrete"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Concrete Works
          </label>
          <Select
            register={register}
            name="cementTypeConcrete"
            items={CEMENT_TYPES}
            placeholder="Select cement type..."
            error={errors.cementTypeConcrete}
            options={cementTypeConcreteOptions}
            className="col-span-12"
          />
          {cementTypeConcrete === 'others' && (
            <Input
              register={register}
              name={'cementTypeConcreteOther'}
              error={errors.cementTypeConcreteOther}
              options={cementTypeConcreteOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="cementTypePlastering"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Cement type used for Plaster / Rendering
          </label>
          <Select
            register={register}
            name="cementTypePlastering"
            items={CEMENT_TYPES}
            placeholder="Select cement type..."
            error={errors.cementTypePlastering}
            options={cementTypePlasteringOptions}
            className="col-span-12"
          />
          {cementTypePlastering === 'others' && (
            <Input
              register={register}
              name="cementTypePlasteringOther"
              error={errors.cementTypePlasteringOther}
              options={cementTypePlasteringOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="reasonWhyNotUsed"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            Major reason why Lafarge product in not been used?
          </label>
          <Select
            register={register}
            name="reasonWhyNotUsed"
            items={REASON_FOR_NOT_USING}
            placeholder="Select reason..."
            error={errors.reasonWhyNotUsed}
            options={reasonWhyNotUsedOptions}
            className="col-span-12"
          />
          {reasonWhyNotUsed === 'others' && (
            <Input
              register={register}
              name="reasonWhyNotUsedOther"
              error={errors.reasonWhyNotUsedOther}
              options={reasonWhyNotUsedOtherOptions}
              placeholder="Please specify..."
              className="col-span-12"
            />
          )}
          <label
            htmlFor="tileAdhesiveIntent"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
           What Tile Adhesive do they intend to use or had used?
          </label>
          <Input
            register={register}
            name="tileAdhesiveIntent"
            error={errors.tileAdhesiveIntent}
            options={tileAdhesiveIntentOptions}
            placeholder="Please specify..."
            className="col-span-12"
          />
          <label
            htmlFor="nextVisitDate"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            What was the agreed date of next visit to site?
          </label>
          <Input
            register={register}
            name="nextVisitDate"
            error={errors.nextVisitDate}
            options={nextVisitDateOptions}
            placeholder="Please specify..."
            className="col-span-12"
            type='date'
          />
          <label
            htmlFor="feedback"
            className="font-bold col-span-12 mb-2 text-lh-head-black"
          >
            What was the Site Representative feedback about your visit?
          </label>
          <Input
            register={register}
            name="feedback"
            error={errors.feedback}
            options={feedbackOptions}
            placeholder="Please specify..."
            className="col-span-12"
          />
        </div>
      </div>
    </>
  );
});

export default SiteVisitProductTypes;
