import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faBullseye,
  faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Sale as SaleTyp } from '../../../../global';
import { useMemo } from 'react';

type SaleProps = {
  sale: SaleTyp;
};

const Sale = ({ sale }: SaleProps) => {
  const navigate = useNavigate();
  const createdOnIcon = useMemo(() => {
    let icon;
    if (!sale) return;
    switch (sale.createdOn) {
      case 'app':
        icon = faTabletAlt;
        break;
      case 'leadTracker':
        icon = faBullseye;
        break;
      default:
        break;
    }
    return icon;
  }, [sale]);
  return (
    <li
      className={
        'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg p-2 cursor-pointer border-lh-red bg-white ' +
        ((sale.status === 'pending' && sale.color === 'red') ||
        (sale.status === 'accepted' && sale.color === 'red')
          ? 'border-2'
          : 'border-0')
      }
      onClick={() => {
        navigate(`/sales/${sale.id}`);
      }}
    >
      <div className="grid grid-cols-10 w-full">
        <div className="col-span-6 flex ml-2">
          <FontAwesomeIcon
            icon={createdOnIcon}
            className="text-lh-head-black self-center mr-2"
            title="Created on"
          />{' '}
          <div>
            <p className="text-lh-head-black align-middle font-medium">
              {sale.name}
            </p>
            <p className="text-sm text-lh-text-black">
              {dayjs(sale.createdAt).format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        </div>
        <p className="col-span-3 text-right self-center mt-1 text-lh-head-black font-semibold">
          {(sale.status === 'accepted' && sale.totalDelivered > 0) ||
          sale.status === 'fulfilled'
            ? sale.totalDelivered + ' / ' + sale.totalOrdered
            : sale.totalOrdered}{' '}
          <span className="text-gray-500 font-light">bags</span>
        </p>
        <p className="col-span-1 text-right self-center mt-1 text-lh-head-black font-bold mr-2">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Sale;
