import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import Button from '../../../components/ui/Button';
import { useLead } from '../../../lib/hooks/use-leads';
import { useUser } from '../../../lib/hooks/use-user';
import { useSiteVisit } from '../../../lib/hooks/use-siteVisits';

type VisitParams = 'id';

type NewLeadFormValues = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  watershield: number;
  //tempProduct1 tempProduct1: number;
  //tempProduct2 tempProduct2: number;
  //tempProduct3 tempProduct3: number;
  //tempProduct4 tempProduct4: number;
  address: string;
};

const SiteVisitStep3 = (props) => {
  const { id } = useParams<VisitParams>();
  const { visit } = useSiteVisit(id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [leadPlaced, setLeadPlaced] = useState(false);

  const { user } = useUser();

  const { create } = useLead();
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors, isValid },
    getValues,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  useEffect(() => {
    if (isSubmitSuccessful) {
      setLeadPlaced(true);
      props.setLeadPlaced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    const watershield = getValues('watershield');
    //tempProduct1 const tempProduct1 = getValues('tempProduct1');
    //tempProduct2 const tempProduct2 = getValues('tempProduct2');
    //tempProduct3 const tempProduct3 = getValues('tempProduct3');
    //tempProduct4 const tempProduct4 = getValues('tempProduct4');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) +
        (isNaN(watershield) ? 0 : watershield) >
      //tempProduct1 (isNaN(tempProduct1) ? 0 : tempProduct1) +
      //tempProduct2 (isNaN(tempProduct2) ? 0 : tempProduct2) +
      //tempProduct3 (isNaN(tempProduct3) ? 0 : tempProduct3) +
      //tempProduct4 (isNaN(tempProduct4) ? 0 : tempProduct4)
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only lead 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };
  const addressOptions: RegisterOptions = {
    required: 'Please enter an address...',
    maxLength: {
      value: 100,
      message: 'Address cannot be more than 100 characters!',
    },
  };

  useEffect(() => {}, []);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(onSubmit)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  const onSubmit: SubmitHandler<NewLeadFormValues> = (data) => {
    if (!isValid) return;
    const siteLocation = props.site.location
      ? props.site.location
      : props.site.changes.location;

    const sop = user.roles.includes('sop') ? user : user.sop;
    create({
      location: siteLocation,
      address: data.address,
      cluster: user.cluster,
      createdAt: Timestamp.now(),
      createdBy: {
        id: user.id,
        name: user.name,
        phone: user.phone,
        roles: user.roles,
      },
      createdOn: 'app',
      site: {
        id: props.site.id,
        name: props.site.name,
      },
      tae: {
        id: user.id,
        name: user.name,
        phone: user.phone,
      },
      products: {
        classic: isNaN(data.classic) ? 0 : data.classic,
        supaset: isNaN(data.supaset) ? 0 : data.supaset,
        supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
        supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
        watershield: isNaN(data.watershield) ? 0 : data.watershield,
        //tempProduct1 tempProduct1: isNaN(data.tempProduct1) ? 0 : data.tempProduct1,
        //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
        //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
        //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
      },
      siteOwner: {
        id: props.site.siteOwner.id,
        name: props.site.siteOwner.name,
        phone: props.site.siteOwner.phone,
      },
      sop: {
        email: sop.email,
        id: sop.id,
        name: sop.name,
        phone: sop.phone,
      },
      status: 'pending',
      statusHistory: {
        pending: Timestamp.now(),
      },
      isDistributorLead: false,
      isVisitLead: true,
      userIds: [user.id, props.site.id, sop.id],
      testIds: [],
      distributor: null,
    });
  };

  return (
    <>
      <Confirm
        title="Are you sure you want to create this new lead?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
      />
      <div className="grid grid-cols-9 col-span-9 ">
        {!leadPlaced && !visit && (
          <>
            <div className="col-span-4 flex justify-left items-center text-left text-xl font-bold text-lh-head-black">
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="mr-2 text-lh-head-black"
              />
              New lead
            </div>
            <form
              name="newLeadForm"
              onSubmit={handleSubmit(onSubmit)}
              className="grid grid-cols-12 col-span-full mt-2"
            >
              <h2 className="font-bold col-span-12 mt-2 mb-2 text-lh-head-black">
                Products
              </h2>
              <label
                htmlFor="classic"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                Classic
              </label>
              <Input
                name="classic"
                register={register}
                options={productOptions}
                error={errors.classic}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="classic"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              <label
                htmlFor="supaset"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                Supaset
              </label>
              <Input
                name="supaset"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="supaset"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              <label
                htmlFor="supafixc0"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                SupafixC0
              </label>
              <Input
                name="supafixc0"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="supafixc0"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              <label
                htmlFor="supafixc1"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                SupafixC1
              </label>
              <Input
                name="supafixc1"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="supafixc1"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              <label
                htmlFor="watershield"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                Watershield
              </label>
              <Input
                name="watershield"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="watershield"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              {/* TempProduct1
              <label
                htmlFor="tempProduct1"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                TempProduct1
              </label>
              <Input
                name="tempProduct1"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="tempProduct1"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              TempProduct1 */}
              {/* TempProduct2
              <label
                htmlFor="tempProduct2"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                TempProduct2
              </label>
              <Input
                name="tempProduct2"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="tempProduct2"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              TempProduct2 */}
              {/* TempProduct3
              <label
                htmlFor="tempProduct3"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                TempProduct3
              </label>
              <Input
                name="tempProduct3"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"
              />
              <label
                htmlFor="tempProduct3"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              TempProduct3 */}
              {/* TempProduct4
              <label
                htmlFor="tempProduct4"
                className="col-span-4 pt-1 text-lh-text-black"
              >
                TempProduct4
              </label>
              <Input
                name="tempProduct4"
                register={register}
                options={productOptions}
                type="number"
                className="col-span-4 text-right col-start-8 text-lh-text-black"  
              />
              <label
                htmlFor="tempProduct4"
                className="col-span-1 ml-2 pt-1 text-lh-text-black"
              >
                bags
              </label>
              TempProduct4 */}
              <label
                htmlFor="address"
                className="col-span-full font-bold text-lh-head-black"
              >
                Delivery Address
              </label>
              <Input
                name="address"
                register={register}
                options={addressOptions}
                error={errors.address}
                type="text"
                className="col-span-full text-lh-text-black"
              />
            </form>
            <Button
              buttonDisabled={!isValid}
              text="Place lead"
              color="blue"
              className="col-span-full"
              onClick={() => {
                setShowConfirmation(true);
              }}
            />
          </>
        )}
        {(leadPlaced || (visit && visit.leadPlaced)) && (
          <p className="col-span-9 text-center">Lead placed successfully!</p>
        )}
        {/* {(!leadPlaced || (siteVisit && !siteVisit.leadPlaced)) && (
          <p className="col-span-9 text-center">
            No lead placed during this siteVisit.
          </p>
        )} */}
      </div>
    </>
  );
};

export default SiteVisitStep3;
