import dayjs from 'dayjs';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Edit from '../../../components/ui/Edit';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Content from '../../../components/layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBullseye,
  faTabletAlt,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { useSale } from '../../../lib/hooks/use-sales';
import FulfillSale from '../../../components/sales/FulfillSale';
import RejectSale from '../../../components/sales/RejectSale';
import AcceptSale from '../../../components/sales/AcceptSale';

type OrderDetailsParams = 'id';

const Sale = () => {
  const { id } = useParams<OrderDetailsParams>();
  const checkAccess = useCheckAccess();
  const navigate = useNavigate();
  const { sale, error, update, loading } = useSale(id);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showFulfill, setShowFulfill] = useState(false);
  const [displayEditButton, setDisplayEditButton] = useState(false);
  const [displayAcceptButton, setDisplayAcceptButton] = useState(false);
  const [displayRejectButton, setDisplayRejectButton] = useState(false);
  const [displayFulfillButton, setDisplayFulfillButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (sale) {
      switch (sale.status) {
        case 'pending':
          setDisplayEditButton(true);
          setDisplayAcceptButton(true);
          setDisplayRejectButton(true);
          break;
        case 'accepted':
          setDisplayFulfillButton(true);
          break;
        default:
          break;
      }
    }
  }, [sale]);

  const handleAccept = async (data) => {
    await update({ status: 'accepted', comment: data.comment });
    navigate('/sales', { replace: true });
  };
  const handleReject = async (data) => {
    await update({ status: 'rejected', comment: data.comment });
    navigate('/sales', { replace: true });
  };
  const handleFulfill = async (data) => {
    const classic = isNaN(data.classic) ? 0 : data.classic;
    const supaset = isNaN(data.supaset) ? 0 : data.supaset;
    const supafixc0 = isNaN(data.supafixc0) ? 0 : data.supafixc0;
    const supafixc1 = isNaN(data.supafixc1) ? 0 : data.supafixc1;
    const watershield = isNaN(data.watershield) ? 0 : data.watershield;
    //tempProduct1 const tempProduct1 = isNaN(data.tempProduct1) ? 0 : data.tempProduct1;
    //tempProduct2 const tempProduct2 = isNaN(data.tempProduct2) ? 0 : data.tempProduct2;
    //tempProduct3 const tempProduct3 = isNaN(data.tempProduct3) ? 0 : data.tempProduct3;
    //tempProduct4 const tempProduct4 = isNaN(data.tempProduct4) ? 0 : data.tempProduct4;

    const productsDelivered = {
      classic: sale?.productsDelivered?.classic
        ? sale.productsDelivered.classic + classic
        : classic,
      supaset: sale?.productsDelivered?.supaset
        ? sale.productsDelivered.supaset + supaset
        : supaset,
      supafixc0: sale?.productsDelivered?.supafixc0
        ? sale.productsDelivered.supafixc0 + supafixc0
        : supafixc0,
      supafixc1: sale?.productsDelivered?.supafixc1
        ? sale.productsDelivered.supafixc1 + supafixc1
        : supafixc1,
      watershield: sale?.productsDelivered?.watershield
        ? sale.productsDelivered.watershield + watershield
        : watershield,
      /* tempProduct1
      tempProduct1: sale?.productsDelivered?.tempProduct1
        ? sale.productsDelivered.tempProduct1 + tempProduct1
        : tempProduct1,
      tempProduct1 */
      /* tempProduct2
      tempProduct2 tempProduct2: sale?.productsDelivered?.tempProduct2
        ? sale.productsDelivered.tempProduct2 + tempProduct2
        : tempProduct2,
      tempProduct2 */
      /* tempProduct3
      tempProduct3 tempProduct3: sale?.productsDelivered?.tempProduct3
        ? sale.productsDelivered.tempProduct3 + tempProduct3
        : tempProduct3,
      tempProduct3 */
      /* tempProduct4
      tempProduct4 tempProduct4: sale?.productsDelivered?.tempProduct4
        ? sale.productsDelivered.tempProduct4 + tempProduct4
        : tempProduct4,
      tempProduct4 */
    };
    await update({
      status: 'fulfilled',
      productsDelivered,
    });
    navigate('/sales', { replace: true });
  };

  const saleIcon = useMemo(() => {
    let icon;
    if (!sale) return;
    switch (sale.status) {
      case 'pending':
        icon = faClock;
        break;
      case 'accepted':
        icon = faCheckCircle;
        break;
      case 'rejected':
        icon = faTimesCircle;
        break;
      case 'fulfilled':
        icon = faTruck;
        break;
      default:
        break;
    }
    return icon;
  }, [sale]);

  const createdOnIcon = useMemo(() => {
    let icon;
    if (!sale) return;
    switch (sale.createdOn) {
      case 'app':
        icon = faTabletAlt;
        break;
      case 'leadTracker':
        icon = faBullseye;
        break;
      default:
        break;
    }
    return icon;
  }, [sale]);

  return (
    <>
      {checkAccess(['sop', 'distributor']) && (
        <RejectSale
          open={showReject}
          onReject={handleReject}
          setOpen={(open) => setShowReject(open)}
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <AcceptSale
          open={showAccept}
          onAccept={handleAccept}
          setOpen={(open) => setShowReject(open)}
        />
      )}
      {checkAccess(['sop', 'distributor']) && (
        <FulfillSale
          open={showFulfill}
          sale={sale}
          onFulfill={handleFulfill}
          setOpen={(open) => setShowFulfill(open)}
          confirmText="Are you sure you want to fulfill the sale?"
          buttonText="Fulfill"
        />
      )}
      <Loader show={loading} />
      <Alert
        message={error?.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <Content>
        {sale ? (
          <Card className="grid grid-cols-10">
            <Back to="/sales" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              <FontAwesomeIcon
                icon={createdOnIcon}
                className="mr-2 text-lh-head-black"
              />
              {sale.id}
            </div>
            {displayEditButton && (
              <Edit className="col-span-3 flex-row-reverse" />
            )}
            <h2 className="col-span-8 font-bold text-lg text-lh-head-black">
              {sale.name}
              <p className="col-span-full text-sm font-thin text-lh-text-black">
                {sale && dayjs(sale.createdAt).format('DD.MM.YYYY HH:mm')}
              </p>
            </h2>
            <div className="col-span-2 flex flex-col items-center font-bold text-lg text-lh-head-black">
              <p>Status</p>
              <FontAwesomeIcon icon={saleIcon} className="text-2xl mt-0" />
            </div>
            <hr className="col-span-full mt-2 mb-2" />
            <h2 className="col-span-full font-bold text-lh-head-black">
              Distributor
            </h2>
            <p className="col-span-full text-lh-text-black">
              {sale?.distributor?.name}
            </p>
            <h2 className="col-span-full font-bold mt-4 mb-2 text-lh-head-black">
              Products fulfilled / ordered
            </h2>
            <h3 className="col-span-2 py-1 text-lh-text-black">Classic</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.classic : 0}{' '}
              {' / '}
              {sale.products?.classic}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Supaset</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.supaset : 0}{' '}
              {' / '}
              {sale.products?.supaset}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC0</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.supafixc0 : 0}{' '}
              {' / '}
              {sale.products?.supafixc0}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">SupafixC1</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.supafixc1 : 0}{' '}
              {' / '}
              {sale.products?.supafixc1}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            <h3 className="col-span-2 py-1 text-lh-text-black">Watershield</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.watershield : 0}{' '}
              {' / '}
              {sale.products?.watershield}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            {/* tempProduct1
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct1</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.tempProduct1 : 0}{' '}
              {' / '}
              {sale.products?.tempProduct1}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct1 */}
            {/* tempProduct2
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct2</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.tempProduct2 : 0}{' '}
              {' / '}
              {sale.products?.tempProduct2}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct2 */}
            {/* tempProduct3
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct3</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.tempProduct3 : 0}{' '}
              {' / '}
              {sale.products?.tempProduct3}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct3 */}
            {/* tempProduct4
            <h3 className="col-span-2 py-1 text-lh-text-black">TempProduct4</h3>
            <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
              {sale.productsDelivered ? sale.productsDelivered.tempProduct4 : 0}{' '}
              {' / '}
              {sale.products?.tempProduct4}
            </p>
            <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
            tempProduct4 */}
            {sale.address && (
              <>
                <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
                  Delivery Address
                </h2>
                <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
                  {sale.address}
                </p>
              </>
            )}
            {sale.comment && (
              <>
                <h2 className="col-span-full font-bold mb-2 text-lh-head-black">
                  Comment
                </h2>
                <p className="col-span-full rounded-lg mb-4 py-1 px-3 border-2 text-lh-text-black">
                  {sale.comment}
                </p>
              </>
            )}
            {checkAccess(['sop', 'distributor']) && displayAcceptButton && (
              <Button
                onClick={() => setShowAccept(true)}
                color="green"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Accept
              </Button>
            )}
            {checkAccess(['sop', 'distributor']) && displayRejectButton && (
              <Button
                onClick={() => setShowReject(true)}
                color="red"
                type="button"
                className="col-span-full mt-2 py-2"
              >
                Reject
              </Button>
            )}
            {checkAccess(['fsa', 'sop', 'distributor']) &&
              displayFulfillButton && (
                <Button
                  onClick={() => setShowFulfill(true)}
                  color="blue"
                  type="button"
                  className="col-span-full mt-2 py-2"
                >
                  Fulfil
                </Button>
              )}
          </Card>
        ) : (
          <Card>Order not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Sale;
